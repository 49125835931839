import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    model: 'navbar-announcement',
    items: [],

    constants: {},

    pagination: {
        limit: 20,
    },

    loading: false,

    viewIndexData: {
        pageTitle: 'Navbar announcements',
        tableHeads: [
            {title: 'Name', key: 'name'},
        ],
        tableProps: {
            create: true,
            show: true,
            destroy: true,
            edit: true,
            group: false,
            search: false,
        }
    },

    viewCreateData: {
        pageTitle: 'Create navbar announcement',
    },

    viewDetailData: {
        pageTitle: 'Navbar announcement details',
        fields: [
            {text: 'Name', key: 'name'},
        ],
    },

    viewEditData: {
        pageTitle: 'Edit navbar announcement',
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};