<template>
    <app-layout>
        <h1 class="text-gray-500 font-bold text-sm font-normal mb-2">Last 30 days</h1>
        <div class="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-8">
            <template v-if="loading">
                <grid-loader />
            </template>
            <template v-if="!loading">
                <template v-for="(stat, key) in shortStats" :key="`short-stats-${key}`">
                    <div class="">
                        <card-short-info
                            :title="stat.title"
                            :value="stat.value"
                            :color="getRandomBgColor()"
                        />
                    </div>
                </template>
            </template>
        </div>
<!--        <h1 class="text-gray-500 font-bold text-sm font-normal mb-2">All-time</h1>-->
<!--        <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">-->
<!--            <template v-if="!loading">-->
<!--                <template v-for="(stat, key) in shortStats" :key="`short-stats-${key}`">-->
<!--                    <div class="">-->
<!--                        <card-short-info-->
<!--                            :title="stat.title"-->
<!--                            :value="stat.value"-->
<!--                            :color="stat.color"-->
<!--                        />-->
<!--                    </div>-->
<!--                </template>-->
<!--            </template>-->
<!--        </div>-->

<!--        <h1 class="text-gray-500 font-bold text-sm font-normal mb-2">Recent referral activity</h1>-->
<!--        <div>-->
<!--            <table-index-->
<!--                :create=false-->
<!--                :heads="tableHeads"-->
<!--                :items="items"-->
<!--                :edit=false-->
<!--                :destroy=false-->
<!--                :show=false-->
<!--            />-->
<!--        </div>-->
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
// import TableIndex from "@/components/ui/table/TableIndex";
import CardShortInfo from "@/components/card-info/CardShortInfo";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import {mapGetters} from "vuex";

export default {
    name: "Index",
    components: {
        CardShortInfo,
        // TableIndex,
        AppLayout,
        GridLoader,
    },
    data() {
        return {
            loading: true,
            item: "",
            radioItem: "usa2",
            checkboxItem: [],
            toggleItem: false,
            pageTitle: 'Welcome',
            shortStats: [],
            selectedFile: null
        };
    },

    computed: {
        ...mapGetters({
            user: 'getUser',
        }),
    },

    created() {
        this.loadStats();
    },

    watch: {
        user(user) {
            if (user) {
                this.$store.dispatch('setNavTitle', `${this.pageTitle} ${user.first_name}`);
            }
        }
    },

    methods: {
        loadStats() {
            this.loading = true;

            this.$axios.get(`/home/short-stats`)
                .then(res => {
                    this.shortStats = res.data;
                    this.loading = false;
                })
                .catch(e => {
                    this.$toast.warning(e.data.message);
                    this.loading = false;
                });
        },
        getRandomBgColor() {
            let colors = [
                'bg-slate-100',
                'bg-red-100',
                'bg-orange-100',
                'bg-yellow-100',
                'bg-lime-100',
                'bg-green-100',
                'bg-cyan-100',
                'bg-blue-100',
                'bg-violet-100',
                'bg-violet-100',
                'bg-purple-100',
                'bg-fuchsia-100',
                'bg-rose-100',
            ]

            return colors[Math.floor(Math.random()*colors.length)];
        }
    }
};
</script>

<style scoped></style>
