import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    model: 'users',
    items: [],

    constants: {},

    pagination: {
        limit: 20,
    },

    loading: false,

    viewIndexData: {
        pageTitle: 'Users',
        tableHeads: [
            {title: 'First name', key: 'first_name'},
            {title: 'Last name', key: 'last_name'},
            {title: 'Email', key: 'email'},
        ],
        tableProps: {
            create: true,
            show: true,
            destroy: true,
            edit: true,
            group: false,
            search: true,
        }
    },

    viewChargesData: {
        pageTitle: 'Charges details',
        tableHeads: [
            {title: 'Amount', key: 'amount'},
            {title: 'Comment', key: 'comment'},
            {title: 'Status', key: 'stripe_charge_status'},
        ],
        tableProps: {
            create: false,
            show: false,
            destroy: false,
            edit: false,
            group: false,
            search: false,
        }
    },

    viewCreateData: {
        pageTitle: 'Create user',
    },

    viewDetailData: {
        pageTitle: 'User details',
        fields: [
            {text: 'First name', key: 'first_name'},
            {text: 'Last name', key: 'last_name'},
            {text: 'Email', key: 'email'},
            {text: 'Role', key: 'role'},
            {text: 'Address', key: 'address'},
            {text: 'Phone number', key: 'phone_number'},
            {text: 'API customer id', key: 'api_customer_id'},
        ],
    },

    viewEditData: {
        pageTitle: 'Edit user',
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};