import * as getters from './getters.js';
import * as mutations from './mutations.js';
import * as actions from './actions.js';

const state = {
    model: 'countries',
    items: [],

    constants: {},

    pagination: {
        limit: 20,
    },

    loading: false,

    viewIndexData: {
        pageTitle: 'Countries',
        tableHeads: [
            {title: 'Name', key: 'name'},
            {title: 'Japanese name', key: 'name_jp'},
            {title: 'Continent', key: 'name', belongs_to: 'continent'},
        ],
        tableProps: {
            create: true,
            show: true,
            destroy: true,
            edit: true,
            group: false,
            search: true,
        }
    },

    viewCreateData: {
        pageTitle: 'Create country',
    },

    viewDetailData: {
        pageTitle: 'Country details',
        fields: [
            {text: 'Name', key: 'name'},
            {text: 'Japanese name', key: 'name_jp'},
            {text: 'Continent', key: 'name', belongs_to: 'continent'},
            {text: 'Price for 1 to 10 days', key: 'price_for_1_to_10_days'},
            {text: 'Price for 11 to 20 days', key: 'price_for_11_to_20_days'},
            {text: 'Price for 21 and more', key: 'price_for_21_to_30_days'},
        ],
    },

    viewEditData: {
        pageTitle: 'Edit continent',
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};