<template>
  <div :class="cardStyles">
    <div class="text-sm font-medium text-gray-500 mb-4">
      {{ title }}
    </div>

    <div class="text-4xl font-semibold text-gray-600 mb-8">
      {{ value }}
    </div>

    <div>
      <button type="button">
        {{ actionText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardShortInfo",

  props: {
    color: {
      type: String,
      default() {
        return "bg-slate-100";
      },
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },
    value: {
      type: String,
      default() {
        return "";
      },
    },
    actionText: {
      type: String,
      default() {
        return "";
      },
    },
  },

    computed: {
        cardStyles() {
            let styles = "p-4 rounded shadow-sm";

            styles += ` ${this.color}`;

            return styles;
        },
    },

  methods: {
  },
};
</script>

<style scoped></style>
