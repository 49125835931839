<template>
    <div>
        <table-confirm-delete-modal
            :model="model"
        />

        <table-actions
            :model="model"
            :create="create"
            :download-excel="downloadExcel"
            :group="group && hasCheckedItem"
            @inputSearch="inputSearch"
        />

        <template v-if="loading">
            <div class="flex justify-center">
                <grid-loader
                    color="#cbd5e1"
                />
            </div>
        </template>

        <template v-if="!loading">
            <template v-if="!items.length">
                <h1>No items</h1>
            </template>

            <template v-if="items.length">
                <div
                    :class="shadows ? ' shadow-sm' : ''"
                    class="relative overflow-x-auto bg-white rounded-lg"
                >
                    <table class="w-full text-sm text-left text-gray-500">

                        <!-- Head -->
                        <thead
                            class="text-slate-400 border-b border-slate-100 text-sx font-normal"
                        >
                        <tr>
                            <th scope="col" class="p-4">
                                <div class="flex items-center">
    <!--                                <input-->
    <!--                                    id="checkbox-all"-->
    <!--                                    type="checkbox"-->
    <!--                                    class="w-4 h-4 text-blue-400 bg-gray-100 border-gray-100 rounded"-->
    <!--                                />-->
    <!--                                <label for="checkbox-all" class="sr-only">checkbox</label>-->
                                </div>
                            </th>

                            <th scope="col" class="px-6 py-3">
                                #
                            </th>

                            <template v-for="head in heads" :key="head.key">
                                <th scope="col" class="px-6 py-3">
                                    {{ head.title }}
                                </th>
                            </template>

                            <th scope="col" class="px-6 py-3">
                                <span class="sr-only">Details</span>
                            </th>
                        </tr>
                        </thead>

                        <!-- Body -->
                        <tbody>

                        <template v-for="(item, i) in items" :key="`${model}-item-${item.id}`">
                            <tr
                                class="bg-white border-b"
                            >
                                <td class="w-4 p-4">
                                    <div class="flex items-center">
                                        <input
                                            id="checkbox-table-1"
                                            type="checkbox"
                                            class="w-4 h-4 text-blue-400 bg-gray-100 border-gray-100 rounded"
                                            :checked="!!item.checked"
                                            @change="check(item)"
                                        />
                                        <label for="checkbox-table-1" class="sr-only">checkbox</label>
                                    </div>
                                </td>

                                <th
                                    scope="row"
                                    class="px-6 py-4 font-medium text-gray-900  whitespace-nowrap"
                                >
                                    {{ i + indexTracker }}
                                </th>

                                <template v-for="head in heads" :key="`${model}-item-${item.id}-${head.key}`">
                                    <template v-if="head.belongs_to">
                                        <belongs-to-column
                                            :head="head"
                                            :item="item"
                                        />
                                    </template>

                                    <template v-else-if="head.type === 'badge'">
                                        <badge-column
                                            :model="model"
                                            :head="head"
                                            :item="item"
                                        />
                                    </template>

                                    <template v-else>
                                        <default-column
                                            :head="head"
                                            :item="item"
                                        />
                                    </template>
                                </template>

                                <!-- Actions -->
                                <td class="px-6 py-4 text-right">
                                    <div class="flex item-center justify-end">
                                        <template v-if="show">
                                            <router-link
                                                :to="`/${model}/${item.id}/show`"
                                                class="w-5 mr-3 transition duration-200 hover:text-blue-500"
                                            >
                                                <fa-icon class="" icon="eye"/>
                                            </router-link>
                                        </template>

                                        <template v-if="edit">
                                            <router-link
                                                :to="`/${model}/${item.id}/edit`"
                                                class="w-5 mr-3 transition duration-200 hover:text-blue-500 ml-2"
                                            >
                                                <fa-icon class="" icon="pen"/>
                                            </router-link>
                                        </template>

                                        <template v-if="destroy">
                                            <button
                                                class="w-5 transition duration-200 hover:text-blue-500 ml-2"
                                                @click="openDeleteConfirmModal(item.id)"
                                            >
                                                <fa-icon class="" icon="trash"/>
                                            </button>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </template>

                        </tbody>
                    </table>
                </div>
                <template class="flex justify-center">
                    <pagination
                        :model="model"
                    />
                </template>
            </template>
        </template>
    </div>
</template>

<script>
import TableConfirmDeleteModal from "./modals/ConfirmDeleteModal.vue";
import TableActions from "./TableActions.vue";
import DefaultColumn from "./modules/columns/DefaultColumn.vue";
import BelongsToColumn from "./modules/columns/BelongsToColumn.vue";
import BadgeColumn from "./modules/columns/BadgeColumn.vue";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import Pagination from "@/components/pagination/Pagination";
import {mapState} from "vuex";

export default {
    name: "TableIndex",
    components: {
        Pagination,
        BadgeColumn,
        BelongsToColumn,
        DefaultColumn,
        TableActions,
        TableConfirmDeleteModal,
        GridLoader
    },
    computed: {
        ...mapState({
            pagination (state, getters) {
                return getters[`${this.model}/getPagination`]
            },
        }),
        indexTracker() {
            let value = 1
            if(this.pagination.current_page === 1) {
                return value
            } else if(this.pagination.current_page === 2) {
                return value = 21
            } else {
                for(let i = 1; i < this.pagination.current_page; i++) {
                    value += 20
                }
            }
            // if(this.pagination.current_page > 1 && this.pagination.current_page % 2) {
            //     value = Math.ceil((this.pagination.current_page + 2) * 10.1)
            // }
            return value
        }
    },
    mounted() {
        console.log(this.items)
    },
    props: {
        model: {
            type: String,
            default() {
                return null;
            },
        },
        heads: {
            type: Array,
            default() {
                return [];
            },
        },
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        create: {
            type: Boolean,
            default() {
                return true;
            }
        },
        downloadExcel: {
            type: Boolean,
            default() {
                return false;
            }
        },
        show: {
            type: Boolean,
            default() {
                return true;
            }
        },
        edit: {
            type: Boolean,
            default() {
                return true;
            }
        },
        destroy: {
            type: Boolean,
            default() {
                return true;
            }
        },
        group: {
            type: Boolean,
            default() {
                return false;
            }
        },
        shadows: {
            type: Boolean,
            default() {
                return true;
            }
        },
// <<<<<<< HEAD
//
//         currentPage: {
//           type: Number,
//           default() {
//             return null
//           }
//         },
//         perPage: {
//           type: Number,
//           default() {
//             return null
//           }
//         },
//         total: {
//           type: Number,
//           default() {
//             return null
//           }
//         },
//         pages: {
//           type: Number,
//           default() {
//             return null
//           }
// =======
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
    },

    data() {
        return {
            hasCheckedItem: false,
            initialPage: this.currentPage,
        }
    },

    methods: {
        openDeleteConfirmModal(id) {
            this.emitter.emit(`show::${this.model}ConfirmDeleteModal`, id);
        },

        check(item) {
            item.checked = !item.checked;

            this.hasCheckedItem = false;

            this.items.forEach(item => {
                if (item.checked) {
                    this.hasCheckedItem = true;
                }
            });
        },
// <<<<<<< HEAD
//         changePage(page) {
//           this.initialPage = page
//           this.$emit('changePage', this.initialPage)
// =======
        inputSearch(value) {
            this.$emit('inputSearch', value)
        }
    },

};
</script>

<style scoped></style>
