import {createRouter, createWebHistory} from "vue-router";

import UsersRoutes from "../views/users/routes"
import DevicesRoutes from "../views/devices/routes"
import AffiliatesRoutes from "../views/affiliates/routes"
import AffiliateLinksRoutes from "../views/affiliate-links/routes"
import ContinentsRoutes from "../views/continents/routes"
import CountriesRoutes from "../views/countries/routes"
import CountryPackagesRoutes from "../views/country-packages/routes"
import CitiesRoutes from "../views/cities/routes"
import CouponsRoutes from "../views/coupons/routes"
import DealersRoutes from "../views/dealers/routes"
import DealerGroupsRoutes from "../views/dealer-groups/routes"
import PagesHomeRoutes from "../views/pages/pages-home/routes"
import NavbarRoutes from "../views/pages/navbar/routes"
import ReportRoutes from "../views/reports/routes"
import SettingsRoutes from "../views/settings/routes"
import GiftCardsRoutes from "../views/gift-cards/routes"

import Login from "@/views/auth/Login";
import HomeIndex from "../views/home/Index.vue";
import UI from "@/views/UI"

import OrdersRoutes from "../views/orders/routes";

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
    },

    {
        path: "/",
        name: "Home",
        component: HomeIndex,
        meta: {
            title: 'Skygo Wifi',
        }
    },

    {
        path: "/ui",
        name: "UI",
        component: UI,
    },

    ...CouponsRoutes,
    ...UsersRoutes,
    ...DevicesRoutes,
    ...AffiliatesRoutes,
    ...AffiliateLinksRoutes,
    ...ContinentsRoutes,
    ...CountriesRoutes,
    ...CountryPackagesRoutes,
    ...CitiesRoutes,
    ...DealersRoutes,
    ...OrdersRoutes,
    ...DealerGroupsRoutes,
    ...SettingsRoutes,
    ...PagesHomeRoutes,
    ...NavbarRoutes,
    ...ReportRoutes,
    ...GiftCardsRoutes,
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
